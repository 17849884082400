<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-col cols="12">
          <b-card-body>
            <b-row>
              <b-col md="9">
                <h2>
                  Clientes
                </h2>
              </b-col>
              <b-col>
                <b-button block variant="gradient-success" @click="adicionar()">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  Adicionar Cliente
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="9" />
              <b-col md="3" />
            </b-row>
            <data-table :data="data" :columns="columns" @on-table-props-changed="reloadTable">
              <tbody slot="body" slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }">
                <tr v-for="item in data" :key="item.id">
                  <td v-for="column in columns" :key="column.name">
                    <data-table-cell :value="item" :name="column.name" :meta="column.meta" :comp="column.component"
                      :classes="column.classes" />
                    <slot v-if="column.label == 'tipo'">
                      <b-badge :variant="Tipo(item).cor">
                        {{ Tipo(item).msg }}
                      </b-badge>
                    </slot>
                    <slot v-if="column.label == 'Fiado'">
                      <b-badge :variant="Carteira(item).cor">
                        {{ Carteira(item).msg }}
                      </b-badge>
                    </slot>
                    <slot v-if="column.label === 'Ações'">
                      <b-button v-b-modal.popupEditar variant="outline-info" class="btn-icon rounded-circle"
                        @click="edicao(item)">
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      url: 'customers/getall',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'name',
          orderable: false,
        },
        {
          label: 'Estado',
          name: 'state',
          orderable: false,
        },
        {
          label: 'cidade',
          name: 'city',
          orderable: false,
        },
        {
          label: 'tipo',
          orderable: false,
        },
        {
          label: 'Fiado',
          orderable: false,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
    }
  },
  async created() {
    this.BuscarDados(this.url)
    this.$loading(true)
  },
  methods: {
    adicionar() {
      this.$router.push('/clientes/add')
    },
    edicao(item) {
      localStorage.setItem('itensPedido', JSON.stringify(item))
      this.$router.push(`/clientes/adicionar/${item.id}`)
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
    Tipo(item) {
      if (item.type === 'F') {
        return { cor: 'light-info', msg: 'Física' }
      }
      if (item.type === 'E') {
        return { cor: 'light-success', msg: 'Estrangeiro' }
      }
      if (item.type === 'J') {
        return { cor: 'light-warning', msg: 'Jurídica' }
      } return { cor: 'light-danger', msg: 'Sem informação' }
    },

    Carteira(item) {
      if (item.wallet === 1) {
        return { cor: 'light-success', msg: 'Ativado' }
      }
      if (item.wallet === 0) {
        return { cor: 'light-secondary', msg: 'Desativado' }
      } return { cor: 'light-warning', msg: 'Sem informação' }
    },
  },

}

</script>

<style scoped>
</style>
